import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Environment } from '../types/environment.types';
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(private http: HttpClient) {}

  public getAllEnvironments() {
    return this.http.get<Environment[]>(environment.apiUrl + '/environment/');
  }
  public searchEnvironments(searchString: string) {
    return this.http.get<Environment[]>(environment.apiUrl + '/environment/?search_str=' + searchString);
  }
}
