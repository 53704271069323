import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PersonalAttribute } from '../types/personal-attribute.types';

@Injectable({
  providedIn: 'root',
})
export class PersonalAttributesService {
  constructor(private http: HttpClient) {}

  public getPersonalAttributes() {
    return this.http.get<PersonalAttribute[]>(environment.apiUrl + '/personal_attributes/');
  }
  public searchPersonalAttribute(searchString: string) {
    return this.http.get<PersonalAttribute[]>(environment.apiUrl + '/personal_attributes/?search_str=' + searchString);
  }
}
