import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Coach } from '../types/coach.types';

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  constructor(private http: HttpClient) {}

  public getCoachOfUser() {
    return this.http.get<Coach>(environment.apiUrl + '/coaches/of_user/');
  }

  public updateCoach(coachId: string, updatedCoach: Partial<Coach>) {
    return this.http.patch<Coach>(environment.apiUrl + '/coaches/' + coachId + '/', updatedCoach);
  }
}
