import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { City } from '../types/city.types';
@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private http: HttpClient) {}

  /**
   * Returns cities
   * @returns cities
   */
  public getCities() {
    return this.http.get<City[]>(environment.apiUrl + '/cities/');
  }

  public searchCities(searchString: string) {
    return this.http.get<City[]>(environment.apiUrl + '/cities/?search_str=' + searchString);
  }
}
