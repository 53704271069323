import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Field } from '../types/field.types';
@Injectable({
  providedIn: 'root',
})
export class FieldService {
  constructor(private http: HttpClient) {}

  public searchField(searchString: string) {
    return this.http.get<Field[]>(environment.apiUrl + '/fields/?search_str=' + searchString);
  }

  public getAllFields() {
    return this.http.get<Field[]>(environment.apiUrl + '/fields/');
  }
}
