import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Skill } from '../types/skill.types';
@Injectable({
  providedIn: 'root',
})
export class SkillService {
  constructor(private http: HttpClient) {}

  public searchSkills(searchString: string) {
    return this.http.get<Skill[]>(environment.apiUrl + '/skills/?search_str=' + searchString);
  }

  public getAllSkills() {
    return this.http.get<Skill[]>(environment.apiUrl + '/skills/');
  }
}
