import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Country } from '../types/country.types';
@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private http: HttpClient) {}

  /**
   * Query all countries
   * @returns countries
   */
  public getCountries() {
    return this.http.get<Country[]>(environment.apiUrl + '/countries/');
  }

  public searchCountries(searchString: string) {
    return this.http.get<Country[]>(environment.apiUrl + '/countries/search/' + searchString + '/');
  }
}
